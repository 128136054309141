<template>
  <div>
    <Loader v-if="groupWatchlist.length == 0" />
    <div class="dashboard__container--body" v-if="groupWatchlist.length > 0">
      <vue-good-table
        :columns="columns"
        :rows="groupWatchlist"
         styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          placeholder: 'Search this table',
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 20,
        }"
      >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'created'">
          <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
        </span>
        <span v-else-if="props.column.field == 'owner'">
          <span v-if="props.row.owner">
            {{props.row.owner.firstName}}
          </span>
          <span v-if="props.row.owner">
            {{props.row.owner.lastName}}
          </span>
        </span>
        <span v-else-if="props.column.field == 'title'">
            <router-link :to="`/groups/` + props.row.id">
              {{props.row.title}}
            </router-link>
          </span>
        <span v-else-if="props.column.field == 'watchlist'">
           <button v-if="isWatchList(props.row)" class="btn btn__icon" @click="removeWatch(props.row)"><i class="fa-solid fa-star warning"></i></button>
        </span>
        <span v-else-if="props.column.field == 'visible'">
          <span v-if="props.row.visible"><i class="fa-regular fa-check"></i></span>
        </span>
        <span v-else-if="props.column.field == 'applicants'">
          <span v-if="props.row.applicants" style="color:green;">
            {{props.row.applicants.length}}
          </span>
        </span>
        <span v-else-if="props.column.field == 'members'">
          <span v-if="props.row.members">
            {{props.row.members.length}}
          </span>
        </span>
        <span v-else-if="props.column.field == 'admins'">
          <span v-if="props.row.admins && props.row.admins.length > 0">
            <button class="btn btn__small chip mr-2" v-for="(item, index) in props.row.admins" :key="item.id">{{item.name}}</button>
          </span>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
    </div>  
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
// import ExportService from "@/services/ExportService"
import router from '@/router'

export default {
  name: 'groupWatchlist',
  data() {
    return {
      columns: [
      {
        label: 'Visible',
        field: 'visible',
        tdClass: 'text-center',
      },
      {
        label: 'Watchlist',
        field: 'watchlist',
        tdClass: 'text-center-all',
        width: '80px'
      },
      {
        label: 'Title',
        field: 'title',
      },
      {
        label: 'Admins',
        field: 'admins',
      },
      {
        label: 'Members',
        field: 'members',
      },
      {
        label: 'New Applicants',
        field: 'applicants',
      },
    ],
    };
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'groupWatchlist']),
  },
  components: {
    Loader,
  },
  created () {
    if (!this.groupWatchlist || this.groupWatchlist.length < 1) {
      this.$store.dispatch("getGroupWatchlist")
    }
  },
  methods: {
    isWatchList(row) {
      if (this.userProfile && this.userProfile.groupWatchList && this.userProfile.groupWatchList.includes(row.id)) {
        return true
      } else {
        return false
      }
    },
    async removeWatch(row) {
      await this.$store.dispatch('unWatchGroup', row.id)
    },
    // onRowClick(params) {
    //   let url = `/groups/` + params.row.id
    //   console.log(url)
    //   router.push(url)
    // },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY')
      } else {
        return null
      }
    },
  },
  beforeDestroy() {
    this.columns = null
    delete this.columns
  },
  destroyed() {
    console.log(this)
  }
}
</script>