var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.groupWatchlist.length == 0)?_c('Loader'):_vm._e(),(_vm.groupWatchlist.length > 0)?_c('div',{staticClass:"dashboard__container--body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.groupWatchlist,"styleClass":"vgt-table condensed","search-options":{
        enabled: true,
        placeholder: 'Search this table',
      },"pagination-options":{
        enabled: true,
        mode: 'records',
        perPage: 20,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'created')?_c('span',[(props.row.created)?_c('span',[_vm._v(_vm._s(_vm.formatDate(props.row.created)))]):_vm._e()]):(props.column.field == 'owner')?_c('span',[(props.row.owner)?_c('span',[_vm._v(" "+_vm._s(props.row.owner.firstName)+" ")]):_vm._e(),(props.row.owner)?_c('span',[_vm._v(" "+_vm._s(props.row.owner.lastName)+" ")]):_vm._e()]):(props.column.field == 'title')?_c('span',[_c('router-link',{attrs:{"to":`/groups/` + props.row.id}},[_vm._v(" "+_vm._s(props.row.title)+" ")])],1):(props.column.field == 'watchlist')?_c('span',[(_vm.isWatchList(props.row))?_c('button',{staticClass:"btn btn__icon",on:{"click":function($event){return _vm.removeWatch(props.row)}}},[_c('i',{staticClass:"fa-solid fa-star warning"})]):_vm._e()]):(props.column.field == 'visible')?_c('span',[(props.row.visible)?_c('span',[_c('i',{staticClass:"fa-regular fa-check"})]):_vm._e()]):(props.column.field == 'applicants')?_c('span',[(props.row.applicants)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(props.row.applicants.length)+" ")]):_vm._e()]):(props.column.field == 'members')?_c('span',[(props.row.members)?_c('span',[_vm._v(" "+_vm._s(props.row.members.length)+" ")]):_vm._e()]):(props.column.field == 'admins')?_c('span',[(props.row.admins && props.row.admins.length > 0)?_c('span',_vm._l((props.row.admins),function(item,index){return _c('button',{key:item.id,staticClass:"btn btn__small chip mr-2"},[_vm._v(_vm._s(item.name))])}),0):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,351051017)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }